import React, { useState, useEffect, KeyboardEvent, useRef, ChangeEvent, forwardRef, useImperativeHandle } from 'react';
import { useVisibilityError } from '@/hooks/useVisibilityError';
import { useRouter } from 'next/navigation';
import ReactMarkdown from 'react-markdown';
import { API_BASE_URL } from '../lib/common';
import { VisibilityIcon, VisibilitySelector } from './Visibility';
import '@/styles/composebox.css'
import { useUserInfo } from '@/context/UserInfoContext';
import { DisplayUserFollow } from '@/lib/api';

interface ComposeBoxProps {
    onClose: () => void;
    topic: string;
    citationKey?: string | null;
    placeholder?: string;
    content?: string;
    onContentChange?: (content: string) => void;
    onExpandedChange?: (expanded: boolean) => void;
    inheritedVisibility?: 'Public' | 'Friends' | 'Private';
    className?: string;
    show_hints?: boolean;
    show_controls?: boolean;
    initialHeight?: string;
    expandedHeight?: string;
}

export interface ComposeBoxHandle {
    submit: () => Promise<void>;
    focus: () => void;
}

export const ComposeBox = forwardRef<ComposeBoxHandle, ComposeBoxProps>((props, ref) => {
    ComposeBox.displayName = 'ComposeBox';
    const composeLabelId = 'compose-label';
    const [content, setContent] = useState<string>(props.content || '');

    useEffect(() => {
        if (props.content !== undefined) {
            setContent(props.content);
        }
        if (textareaRef.current && props.initialHeight) {
            textareaRef.current.style.height = props.initialHeight;
        }
    }, [props.content, props.initialHeight]);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [hasContent, setHasContent] = useState<boolean>(false);
    const [isPosting, setIsPosting] = useState<boolean>(false);
    const [showUserSuggestions, setShowUserSuggestions] = useState<boolean>(false);
    const [userSuggestions, setUserSuggestions] = useState<string[]>([]);
    const [cursorPosition, setCursorPosition] = useState<number>(0);
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState<number>(0);
    const [visibility, setVisibility] = useState<'Public' | 'Friends' | 'Private'>(
        props.inheritedVisibility || 'Public'
    );
    const visibilityError = useVisibilityError(content, visibility, props.inheritedVisibility);
    const [showVisibilityDropdown, setShowVisibilityDropdown] = useState(false);
    const router = useRouter();
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const { userInfo } = useUserInfo();


    useEffect(() => {
        // TODO: eliminate this and use CSS
        const updateSuggestionBoxPosition = () => {
            if (showUserSuggestions && textareaRef.current) {
                const textarea = textareaRef.current;
                const { selectionStart, selectionEnd } = textarea;
                
                if (selectionStart === selectionEnd) {
                    const { scrollLeft, scrollTop } = textarea;
                    const textBeforeCursor = content.substring(0, selectionStart);
                    const lines = textBeforeCursor.split('\n');
                    const currentLineIndex = lines.length - 1;
                    const currentLineText = lines[currentLineIndex];
                    
                    const textareaStyles = window.getComputedStyle(textarea);
                    const lineHeight = parseInt(textareaStyles.lineHeight);
                    const fontSize = parseInt(textareaStyles.fontSize);
                    const paddingLeft = parseInt(textareaStyles.paddingLeft);
                    const paddingTop = parseInt(textareaStyles.paddingTop);
                    
                    const charWidth = fontSize * 0.6; // Approximate character width
                    const left = paddingLeft + (currentLineText.length * charWidth) - scrollLeft;
                    const top = paddingTop + ((currentLineIndex + 2) * lineHeight) - scrollTop;
                    
                    const suggestionBox = document.getElementById('user-suggestions');
                    if (suggestionBox) {
                        const rect = textarea.getBoundingClientRect();
                        suggestionBox.style.position = 'absolute';
                        suggestionBox.style.top = `${rect.top + top + lineHeight}px`; // Add one more lineHeight
                        suggestionBox.style.left = `${rect.left + left}px`;
                        suggestionBox.style.maxHeight = `${window.innerHeight - (rect.top + top + lineHeight) - 20}px`; // Adjust maxHeight
                    }
                }
            }
        };

        updateSuggestionBoxPosition();
    }, [showUserSuggestions, content, cursorPosition]);

    const containsMarkdown = (text: string): boolean => {
        // Regular expression to match common Markdown syntax
        const markdownRegex = /(\*\*|__|\*|_|`|#|\[.*\]\(.*\)|!\[.*\]\(.*\)|\n\s*[-*+]\s|\n\s*\d+\.\s|\n\s*>\s)/;
        return markdownRegex.test(text);
    };

    useImperativeHandle(ref, () => ({
        submit: handlePost,
        focus: () => textareaRef.current?.focus()
    }));

    const handlePost = async () => {
        setIsPosting(true);
        try {
            const topic = props.topic;
            const requestBody: {
                content: string;
                topic: string;
                visibility: string
                citation?: string
            } = { content, topic, visibility: visibility };

            console.log('Posting content:', requestBody);

            if (props.citationKey) {
                requestBody.citation = props.citationKey;
            }

            const response = await fetch(`${API_BASE_URL}/doc/new`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                throw new Error('Failed to post content');
            }

            const responseData = await response.json();
            console.log('New doc key:', responseData.doc_key);

            // Handle successful post: close compose box and redirect
            props.onClose();
            router.push(`/doc/${responseData.doc_key}`);
        } catch (error) {
            console.error('Error posting content:', error);
            // Handle error (e.g., show an error message to the user)
        } finally {
            setIsPosting(false);
        }
    };

    const showPreview = containsMarkdown(content);

    const handleTextareaFocus = () => {
        setIsExpanded(true);
        if (props.onExpandedChange) {
            props.onExpandedChange(true);
        }
        if (textareaRef.current && props.expandedHeight) {
            textareaRef.current.style.height = props.expandedHeight;
        }
    };

    const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newContent = e.target.value;
        setContent(newContent);
        setCursorPosition(e.target.selectionStart);
        setHasContent(newContent.length > 0);

        // Call the onContentChange callback if provided
        if (props.onContentChange) {
            props.onContentChange(newContent);
        }

        const lastAtSymbolIndex = newContent.lastIndexOf('@', e.target.selectionStart);
        if (lastAtSymbolIndex !== -1 && e.target.selectionStart > lastAtSymbolIndex) {
            const query = newContent.slice(lastAtSymbolIndex + 1, e.target.selectionStart).toLowerCase();
            const suggestions = userInfo?.following
                ?.filter((follow: DisplayUserFollow) => follow.username.toLowerCase().startsWith(query))
                .map((follow: DisplayUserFollow) => follow.username) || [];
            setUserSuggestions(suggestions);
            setShowUserSuggestions(suggestions.length > 0);
            setSelectedSuggestionIndex(0);
        } else {
            setShowUserSuggestions(false);
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (showUserSuggestions) {
            if (e.key === 'ArrowDown') {
                e.preventDefault();
                setSelectedSuggestionIndex((prevIndex) =>
                    (prevIndex + 1) % userSuggestions.length
                );
            } else if (e.key === 'ArrowUp') {
                e.preventDefault();
                setSelectedSuggestionIndex((prevIndex) =>
                    (prevIndex - 1 + userSuggestions.length) % userSuggestions.length
                );
            } else if (e.key === 'Enter' && userSuggestions.length > 0) {
                e.preventDefault();
                handleUserSuggestionClick(userSuggestions[selectedSuggestionIndex]);
            }
        } else if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
            e.preventDefault();
            if (content.trim() && !isPosting && !visibilityError) {
                handlePost();
            }
        }
    };

    const handleUserSuggestionClick = (username: string) => {
        const lastAtSymbolIndex = content.lastIndexOf('@', cursorPosition);
        const newContent = content.slice(0, lastAtSymbolIndex + 1) + username + content.slice(cursorPosition);
        setContent(newContent);
        setShowUserSuggestions(false);
        if (textareaRef.current) {
            const newCursorPosition = lastAtSymbolIndex + username.length + 1;
            textareaRef.current.focus();
            textareaRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
            setCursorPosition(newCursorPosition);
        }
    };

    return (
        <section className={`compose-box ${props.className || ''}`} role="form" aria-labelledby={composeLabelId}>
            <label id={composeLabelId} htmlFor="compose-textarea" className="sr-only">
                Compose your post
            </label>
            <div className="compose-input-container">
                <textarea
                    id="generic-textarea"
                    ref={textareaRef}
                    value={content}
                    inputMode="text"
                    autoComplete="off"
                    onChange={handleTextareaChange}
                    onKeyDown={handleKeyDown}
                    onFocus={handleTextareaFocus}
                    placeholder={props.placeholder || 'Reply...'}
                    aria-describedby="compose-instructions"
                />
                {showUserSuggestions && (
                    <ul 
                        id="user-suggestions" 
                        className="user-suggestions no-bullets" 
                        role="listbox"
                        aria-label="User suggestions"
                    >
                        {userSuggestions.map((username, index) => (
                            <li
                                key={username}
                                id={`user-suggestion-${index}`}
                                role="option"
                                aria-selected={index === selectedSuggestionIndex}
                                className={`user-suggestion ${index === selectedSuggestionIndex ? 'selected' : ''}`}
                                onClick={() => handleUserSuggestionClick(username)}
                                tabIndex={0}
                            >
                                {username}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            {/* TODO: these instructions might be generally useful */}
            <span id="compose-instructions" className="sr-only">
                Use @ to mention users. Press Enter to select a suggestion.
            </span>
            {props.show_hints !== false && hasContent && (
              <p className="compose-help">
                Use @ to tag friends.
                {/* Use <a href="https://commonmark.org/help/" target="_blank" rel="noopener noreferrer">markdown</a> for formatting. */}
              </p>
            )}
            {isExpanded && (
                <>
                    {showPreview && (
                        <section className="preview">
                            <h3>Preview:</h3>
                            <ReactMarkdown>{content}</ReactMarkdown>
                        </section>
                    )}
                    {visibilityError && (
                        <div className="error-message" role="alert">
                            {visibilityError}
                        </div>
                    )}
                    {props.show_controls !== false && (
                        <span className="compose-box-buttonrow">
                            <VisibilitySelector
                                visibility={visibility}
                                setVisibility={setVisibility}
                                inheritedVisibility={props.inheritedVisibility}
                                showDropdown={showVisibilityDropdown}
                                setShowDropdown={setShowVisibilityDropdown}
                            />
                            <button 
                                className="controls" 
                                onClick={handlePost}
                                disabled={isPosting || !!visibilityError}
                                aria-busy={isPosting}
                                title={visibilityError || undefined}
                            >
                                {isPosting ? 'Posting...' : 'Post'}
                            </button>
                            <button 
                                className="controls" 
                                onClick={() => {
                                    setIsExpanded(false);
                                    setContent('');
                                    if (textareaRef.current) {
                                        textareaRef.current.style.height = props.initialHeight || '';
                                    }
                                    props.onExpandedChange?.(false);
                                    props.onClose();
                                }}
                                aria-label="Discard message"
                            >
                                Discard
                            </button>
                        </span>
                    )}
                </>
            )}
        </section>
    );
});
