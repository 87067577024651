import { FaGlobe, FaUserFriends, FaUser } from "react-icons/fa";
import { useRef, useEffect } from "react";

interface VisibilityIconProps {
  visibility: string;
  className?: string;
  disabled?: boolean;
}

export const VisibilityIcon: React.FC<VisibilityIconProps> =
    ({ visibility, className, disabled }) => {
  const getIcon = () => {
    switch (visibility) {
      case "Public":
        return <FaGlobe aria-label="Public visibility" />;
      case "Friends":
        return <FaUserFriends aria-label="Friends-only visibility" />;
      case "Private":
        return <FaUser aria-label="Private visibility" />;
      default:
        return null;
    }
  };

  return (
    <span 
      className={`${className} ${disabled ? 'visibility-disabled' : ''}`}
      title={`Post visibility: ${visibility}${disabled ? ' (unavailable)' : ''}`}
      role="img"
      aria-label={`Post visibility: ${visibility}${disabled ? ' (unavailable)' : ''}`}
    >
      {getIcon()}
    </span>
  );
};

interface VisibilitySelectorProps {
  visibility: 'Public' | 'Friends' | 'Private';
  setVisibility: (visibility: 'Public' | 'Friends' | 'Private') => void;
  inheritedVisibility?: 'Public' | 'Friends' | 'Private';
  showDropdown: boolean;
  setShowDropdown: (show: boolean) => void;
  position?: 'above' | 'below';
}

export const VisibilitySelector: React.FC<VisibilitySelectorProps> = ({
  visibility,
  setVisibility,
  inheritedVisibility,
  showDropdown,
  setShowDropdown,
  position
}) => {
  const visibilityRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (visibilityRef.current && !visibilityRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setShowDropdown]);

  return (
    <div className="visibility-selector" ref={visibilityRef}>
      <button 
        onClick={(e) => {
          e.preventDefault();
          setShowDropdown(!showDropdown);
        }}
        className="visibility-button"
      >
        <span style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <VisibilityIcon visibility={visibility} />
        </span>
      </button>
      {showDropdown && (
        <div className={`visibility-dropdown ${position || 'above'}`}>
          {['Public', 'Friends', 'Private'].map((option) => {
            const isDisabled = (() => {
              if (!inheritedVisibility) return false;
              const restrictiveness = {
                'Public': 0,
                'Friends': 1,
                'Private': 2
              };
              return restrictiveness[option as keyof typeof restrictiveness] < 
                     restrictiveness[inheritedVisibility];
            })();

            return (
              <button
                key={option}
                onClick={(e) => {
                  e.preventDefault();
                  if (!isDisabled) {
                    setVisibility(option as 'Public' | 'Friends' | 'Private');
                    setShowDropdown(false);
                  }
                }}
                className={`visibility-option ${visibility === option ? 'selected' : ''} ${isDisabled ? 'disabled' : ''}`}
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                disabled={isDisabled}
              >
                <VisibilityIcon visibility={option} disabled={isDisabled} />
                {option}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};
